// Bootstrap JS
import 'bootstrap';

// Import CSS
import './../css/main.scss';

/**
 * Execute functions after DOM load
 */
document.addEventListener('DOMContentLoaded', () => {
    smoothScrolling();
});

// Modal
var modalEle = document.querySelector(".modal");
var modalImage = document.querySelector(".modalImage");
Array.from(document.querySelectorAll(".imgModal")).forEach(item => {
    item.addEventListener("click", event => {
        modalEle.style.display = "block";
        modalImage.src = event.target.src;
    });
});
document.querySelector(".close").addEventListener("click", () => {
    modalEle.style.display = "none";
});

/**
 * Enable Smooth Scrolling
 * Based on the solution by Chris Coyier of CSS-Tricks.com
 */
var smoothScrolling = () => {
    document.querySelectorAll('a.smoothscroll[href*="#"]').forEach(function(item, idx) {
        item.addEventListener('click', (e) => {
            e.preventDefault();
            smoothScrollTo(item);
        });
    });
}

/**
 * Smooth scrolls from item in page
 * @param {Object} linkItem 
 */
const smoothScrollTo = (linkItem) => {
    if (location.pathname.replace(/^\//, '') == linkItem.pathname.replace(/^\//, '') && location.hostname == linkItem.hostname) {
        let target = linkItem.hash;
        target = target.length ? target : document.querySelector('[name=' + linkItem.hash.slice(1) + ']');
        let stickynavLength = document.querySelector('.top-navbar.sticky-top').offsetHeight;
        stickynavLength = stickynavLength == undefined ? 0 : stickynavLength;
        if (target.length) {
            window.scroll({
                behavior: 'smooth',
                left: 0,
                top: document.querySelector(target).offsetTop - stickynavLength
            });
        }
    }
};

const stickyHeader = () => {
    // When the user scrolls the page, execute myFunction
    window.onscroll = function() { myFunction() };

    // Get the header
    var header = document.querySelector('.page-header');

    // Get the offset position of the navbar
    var sticky = header.offsetTop;

    // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
    function myFunction() {
        if (window.pageYOffset >= 1) {
            header.classList.add("sticky");
        } else {
            header.classList.remove("sticky");
        }
    }
};
stickyHeader();